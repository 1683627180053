import React from "react";
import SkimmerPool from "../../../images/catalogue/skimmer_pool.jpg";
import OverflowPool from "../../../images/catalogue/overflow_pool.jpg";
import Jacuzzi from "../../../images/catalogue/jacuzzi.jpg";
import Sauna from "../../../images/catalogue/sauna.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Item = ({ logo, name, desc }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="partner" data-aos="fade-up">
      <div className="partner-logo">
        <img src={logo} alt={name} />
      </div>
      <div className="partner-content">
        <div className="partner-name">{name}</div>
        <div className="partner-desc">{desc}</div>
      </div>
    </div>
  );
};

const ExclusivePartners = () => {
  const { t } = useTranslation();

  return (
    <div className="exclusive-partners">
      <div className="landing-info">
        <div className="small-title mid">{t("partners")}</div>
        <div className="page-title"> {t("exclusivePartners")}</div>
        <div className="page-content">{t("partnersText")}</div>
      </div>
      <div className="grid-partners">
        <Item logo={SkimmerPool} name="Skimmer Pools" desc={t("skimmerpool")} />
        <Item
          logo={OverflowPool}
          name="Overflow Pools"
          desc={t("overflowpool")}
        />
        <Item logo={Jacuzzi} name="Jacuzzi's" desc={t("jacuzzi")} />
        <Item logo={Sauna} name="Sauna's" desc={t("sauna")} />
      </div>
    </div>
  );
};

export default ExclusivePartners;
