import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer-part">
        <div className="footer-part-title">CHAUD DE L'EAU</div>
        <div className="footer-part-text">Reningelststraat 123 </div>
        <div className="footer-part-text">B-8950 Heuvelland</div>
        <div className="footer-part-text">{t("belgium")}</div>
        <div className="footer-part-text top-15">BTW: BE 0766.312.866</div>
      </div>
      <div className="footer-part">
        <div className="footer-part-title">{t("contact")}</div>
        <div className="footer-part-text">+32 473 98 33 82</div>
        <div className="footer-part-text">info@chaudeleau.com</div>
      </div>
      <div className="footer-part">
        <div className="footer-part-title">{t("followUs")}</div>
        <div className="footer-part-text">
          <a href="https://www.facebook.com/profile.php?id=100064304282171">
            Facebook
          </a>
        </div>
        {/* <div className="footer-part-text">
          <a href="https://www.linkedin.com/in/isytech-bv-320b532b9/">
            LinkedIn
          </a>
        </div>*/}
      </div>
    </footer>
  );
};

export default Footer;
